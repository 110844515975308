<template>
    <el-container class="nav-container">
    <el-header style="position: fixed;top: 0;left:0;z-index: 1000;width: 100%;">
      <div>
        <img id="logo" src="../assets/img/head_logo.png" @click="$router.push('/')" style="cursor:pointer;margin: 0 32px" alt="">
        <el-menu
            class="lg"
            :default-active="$route.meta.name"
            mode="horizontal"
            background-color="#393948"
            text-color="#A9A9AD"
            router
            active-text-color="#AB54DB">
          <el-menu-item index="/">{{$t('center.home_page')}}</el-menu-item>
          <el-menu-item index="/user_center">{{$t('center.user_center')}}</el-menu-item>
          <el-menu-item index="/robot">{{$t('center.robot')}}</el-menu-item>
<!--          <el-menu-item index="/rebate">{{$t('center.rebate')}}</el-menu-item>-->
<!--          <el-menu-item index="/grant_member">{{$t('center.member')}}</el-menu-item>-->
          <el-menu-item index="/user">{{$t('center.my')}}</el-menu-item>
<!--          <el-menu-item v-else>我的</el-menu-item>-->
        </el-menu>
      </div>
      <div class="icons">
<!--        <img class="img-sm" @click="torobot" style="width: 24px;height: 24px" src="../assets/img/center/robot.svg" alt="">-->
        <el-popover
            class="el-pop"
            placement="bottom"
            title="公告"
            width="300"
            trigger="click"
            v-model="visibleSm" style="position: relative">
          <div class="img-sm" :class="isShowNotice1?' shake':''" slot="reference" style="position: relative;margin-right: 20px;" >
            <img style="vertical-align: middle;margin-right: 0" src="../assets/img/notice.svg">
            <div v-if="isShowNotice1" style="position:absolute;left:0;top:0;background-color: #FF4D4F;width: 10px;height: 10px;border-radius: 50%"></div>
          </div>
          <div v-if="noticeMsgList && noticeMsgList.length != 0" style="text-align: left;cursor: pointer;padding: 6px 0" v-for="(item, index) in noticeMsgList" @click="gotoLuckDraw(item)">
            <span style="display: inline-block;color:#1E2329;margin-top: 0px;width: 75%;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;vertical-align: middle">{{ item.title }}</span>
            <span style="display: inline-block;color:#B0B5BA;margin-top: 0px;width:25%;text-align: right;vertical-align: middle">{{ item.date }}</span>
          </div>
          <span v-if="noticeMsgList && noticeMsgList.length != 0" style="display:block; width: 100%;margin-top: 10px;text-align: right;cursor: pointer" @click="$router.push('../../helpNoticeAll?titleNum=5_1&lastPage=lastPage')">查看更多>></span>
          <span v-else style="display: block;color:#B0B5BA;margin-top: 16px">暂无内容</span>
        </el-popover>
        <img class="img-sm" @click="handleDrawerSwitch" style="width: 24px;height: 24px;margin-right: 0" src="../assets/img/center/navigation.svg" alt="">
<!--        <img class="img-lg" src="../assets/img/center/center.png" alt="">-->
        <img class="download-app" src="../assets/img/download.svg" style="cursor:pointer;" @click="download_app()" alt="">
        <!--点击图标弹出退出菜单-->
        <el-dropdown placement="bottom-start" trigger="click">
          <span class="el-dropdown-link" style="display:inline-flex;align-items: center; ">
            <img v-if="!userInfo || userInfo.user_face=='' || userInfo.user_face==null" style="width: 30px;height: 30px;border-radius: 50%" class="img-lg" src="../assets/img/avatar.png" alt="" >
            <img v-else style="width: 30px;height: 30px;border-radius: 50%" class="img-lg" :src="userInfo.user_face" alt="" >
<!--            <pop_image v-if="userInfo.user_face=='' || userInfo.user_face==null" style="margin-right: 24px;vertical-align: middle" class="img-lg" :imgUrl="''" :placement="'bottom'" alt="" ></pop_image>-->
<!--            <pop_image v-else style="margin-right: 24px;vertical-align: middle" class="img-lg" :imgUrl="userInfo.user_face" :placement="'bottom'" alt="" ></pop_image>-->
            <span class="img-lg" style="font-size: 13px;color: #A9A9AD;margin: 0px 4px 0 -16px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{userNavName}}</span>
          </span>
              <el-dropdown-menu slot="dropdown" class="menuImg">
                <div @click="$router.push('/user/property')" @mouseenter="handleMouseEnterMenu(1)" @mouseleave="handleMouseLeaveMenu(1)">
                  <el-dropdown-item style="display: flex;align-items: center;justify-content: flex-start;min-width: 100px;height: 40px"><img style="width:22px;margin-right: 5px;" src="../assets/img/menu/menu_wallet.svg" alt=""><span> {{$t('center.my_property')}}</span></el-dropdown-item>
                </div>
<!--                <div @click="$router.push('/user/member')" @mouseenter="handleMouseEnterMenu(2)" @mouseleave="handleMouseLeaveMenu(2)">-->
<!--                  <el-dropdown-item style="display: flex;align-items: center;justify-content: flex-start;height: 40px"><img style="width:22px;margin-right: 5px;" src="../assets/img/menu/menu_income.svg" alt=""><span> {{$t('center.my_member')}}</span></el-dropdown-item>-->
<!--                </div>-->
                <div @click="$router.push('/user/personal')" @mouseenter="handleMouseEnterMenu(3)" @mouseleave="handleMouseLeaveMenu(3)">
                  <el-dropdown-item style="display: flex;align-items: center;justify-content: flex-start;min-width: 100px;height: 40px"><img style="width:22px;margin-right: 5px;" src="../assets/img/menu/menu_user.svg" alt=""><span> {{$t('center.personal_data')}}</span></el-dropdown-item>
                </div>
                <div @click="$router.push('/user/safe')" @mouseenter="handleMouseEnterMenu(4)" @mouseleave="handleMouseLeaveMenu(4)">
                  <el-dropdown-item style="display: flex;align-items: center;justify-content: flex-start;min-width: 100px;height: 40px"><img style="width:22px;margin-right: 5px;" src="../assets/img/menu/menu_safe.svg" alt=""><span>{{$t('center.safe')}}</span></el-dropdown-item>
                </div>
                <div @click="handleToExit" @mouseenter="handleMouseEnterMenu(5)" @mouseleave="handleMouseLeaveMenu(5)">
                  <el-dropdown-item style="display: flex;align-items: center;justify-content: flex-start;height: 40px"><img style="width:22px;margin-right: 5px;" src="../assets/img/menu/menu_exit.svg" alt=""><span> {{$t('center.exit')}}</span></el-dropdown-item>
                </div>
              </el-dropdown-menu>
        </el-dropdown>

        <el-dropdown class="lg" placement="bottom-start" trigger="click" style="margin-left: 30px;" @command="selectLang">
          <span class="el-dropdown-link" style="display:inline-flex;align-items: center; ">
            <img style="width: 23px;height: 23px;border-radius: 50%;margin-right: 5px" src="../assets/img/home/change_language_icon.png" alt="" >
          </span>
          <el-dropdown-menu slot="dropdown" class="menuImg">
            <el-dropdown-item :class="{language_selected: selectLanguage == '简体中文'}" command="简体中文">简体中文</el-dropdown-item>
            <el-dropdown-item :class="{language_selected: selectLanguage == '繁体中文'}" command="繁体中文">繁体中文</el-dropdown-item>
            <el-dropdown-item :class="{language_selected: selectLanguage == 'English'}" command="English">English</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

<!--        <img @click="$router.push('/grant_member')" class="img-lg" v-if="userInfo.charge_style2==3" src="../assets/img/ssvip.svg" alt="" style="margin-right: 5px;">-->
<!--        <img @click="$router.push('/grant_member')" class="img-lg" v-else-if="userInfo.charge_style2==2" src="../assets/img/SVIP.svg" alt="" style="margin-right: 5px">-->
<!--        <img @click="$router.push('/grant_member')" class="img-lg" v-else src="../assets/img/VIP.svg" alt="" style="margin-right: 5px;">-->
<!--        <img v-if="userInfo.charge_style2 == 2 || userInfo.charge_style2 == 3" class="img-lg" style="margin-right: 20px" src="../assets/img/user/discount.svg">-->
<!--        <img class="img-lg" src="../assets/img/center/found.png" alt="">-->
        <el-popover
            class="el-pop"
            placement="bottom"
            title="公告"
            :popper-append-to-body="false"
            width="400"
            trigger="click"
            v-model="visible" style="position: relative;">
          <div class="img-lg" :class="isShowNotice1?' shake':''" slot="reference" style="position: relative;margin: 0 20px" >
            <img style="vertical-align: middle; margin-right: 0" src="../assets/img/notice.svg">
            <div v-if="isShowNotice1" style="position:absolute;left:0;top:0;background-color: #FF4D4F;width: 10px;height: 10px;border-radius: 50%"></div>
          </div>
          <div v-if="noticeMsgList && noticeMsgList.length != 0" style="text-align: left;cursor: pointer;margin: 15px 0" v-for="(item, index) in noticeMsgList" @click="gotoLuckDraw(item)">
            <span style="display: inline-block;color:#1E2329;margin-top: 0px;width: 80%;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;vertical-align: middle">{{ item.title }}</span>
            <span style="display: inline-block;color:#B0B5BA;margin-top: 0px;text-align: right;width: 20%;vertical-align: middle">{{ item.date }}</span>
          </div>
          <span v-if="noticeMsgList && noticeMsgList.length != 0" style="display:block; width: 100%;margin-top: 10px;text-align: right;cursor: pointer" @click="$router.push('../../helpNoticeAll?titleNum=5_1&lastPage=lastPage')">查看更多>></span>
          <span v-else style="display: block;color:#B0B5BA;margin-top: 16px">暂无内容</span>
        </el-popover>
      </div>
    </el-header>

      <div v-if="showCardActivity" style="display: block; width: 100%; margin-top: 60px;">
        <div v-if="lgShow" style="background: #000000; height: 50px; line-height: 50px; text-align: center">
          <img style="display: inline-block; vertical-align: middle; margin-right: 10px; width: 21px;" src="../assets/img/icon/guangbo_icon.png">
          <span style="color: #FFFFFF; font-size: 16px; display: inline-block; letter-spacing: 0.07em; font-weight: 500"><span class="guanggao-t-1">UPLUS，</span><span class="guanggao-t-2">重磅升级！</span>所有合约用户燃料费/点卡<span class="guanggao-t-3">7折</span>减免中..</span>
        </div>
        <div v-else style="background: linear-gradient(100deg, #6f359f 0%, #4e62e4 70%, #573599 100%); width: 100%; margin: 0 auto; height: 40px; line-height: 40px; ">
          <span class="scrolling-text" style="display: inline-block; width: 100%; color: #FFFFFF; margin: 0 auto; font-size: 14px">UPLUS，重磅升级！所有合约用户燃料费/点卡7折减免中..</span>
        </div>
      </div>
      <div v-else style="margin-bottom: 60px"></div>
    <el-container class="lg" v-if="lgShow">
      <div style="margin-right: 208px" v-if="$route.path!=='/rebate' && $route.path.indexOf('/auto_detail') == -1 && $route.path.indexOf('/semi_detail') == -1 && $route.path.indexOf('/martin_detail') == -1 && $route.path.indexOf('/profit_details') == -1 && $route.path.indexOf('/contract_detail') == -1 && $route.path.indexOf('/term_detail') == -1"></div>
      <el-aside width="208px"  style="position: fixed;left:0;z-index: 100;" v-if="$route.path!=='/rebate' && $route.path.indexOf('/auto_detail') == -1 && $route.path.indexOf('/semi_detail') == -1 && $route.path.indexOf('/martin_detail') == -1">
        <!--      用户中心的sidebar-->
        <el-menu
            v-if="$route.path==='/user_center'||$route.path==='/earn_rank'"
            default-active="1"
            class="menu-vertical"
            mode="vertical"
            text-color="#1E2329"
            active-text-color="#AB54DB">
<!--          <div class="user-icons-info">-->
<!--            <img src="../assets/img/avatar.png" alt="">-->
<!--            <div>{{username}}</div>-->
<!--            <img style="cursor: pointer" @click="$router.push('/grant_member')" class="img-lg" v-if="userInfo.charge_style2==3" src="../assets/img/ssvip.svg" alt="">-->
<!--            <img style="cursor: pointer" @click="$router.push('/grant_member')" v-else-if="userInfo.charge_style2==2" src="../assets/img/SVIP.svg" alt="">-->
<!--            <img style="cursor: pointer" @click="$router.push('/grant_member')" v-else src="../assets/img/VIP.svg" alt="">-->
<!--          </div>-->
          <el-menu-item @click="handleAside(0)" index="1">{{$t('center.user_center_tab1')}}</el-menu-item>
          <el-menu-item @click="handleAside(370)" index="2">{{$t('center.user_center_tab2')}}</el-menu-item>
          <el-menu-item @click="handleAside(528)" index="3">{{$t('center.user_center_tab3')}}</el-menu-item>
        </el-menu>
        <!--      机器人中心的sidebar-->

        <el-menu
            v-if="$route.path==='/robot'"
            default-active="4"
            class="menu-vertical"
            mode="vertical"
            text-color="#1E2329"
            active-text-color="#AB54DB">
          <el-menu-item @click="handleAside(0)" index="4">{{$t('center.robot_tab1')}}</el-menu-item>
          <el-menu-item @click="handleAside(330)" index="5">{{$t('center.robot_tab2')}}</el-menu-item>
          <el-menu-item @click="handleAside(1489)" index="6">{{$t('center.robot_tab3')}}</el-menu-item>
        </el-menu>

        <!--      安全中心的sidebar-->
          <el-menu
              v-if="$route.path==='/user/member' || $route.path=='/user/safe' || $route.path == '/user/personal' || $route.path=='/user/property' || $route.path=='/user/ApiPage'"
              default-active="/user/property"
              class="menu-vertical"
              mode="vertical"
              router
              text-color="#1E2329"
              active-text-color="#AB54DB">
            <el-menu-item index="/user/property">{{$t('center.safe_tab1')}}</el-menu-item>
<!--            <el-menu-item index="/user/member">{{$t('center.safe_tab2')}}</el-menu-item>-->
            <el-menu-item index="/user/personal">{{$t('center.safe_tab3')}}</el-menu-item>
            <el-menu-item index="/user/safe">{{$t('center.safe_tab4')}}</el-menu-item>
<!--            <el-menu-item index="/user/ApiPage">{{$t('center.safe_tab5')}}</el-menu-item>-->
          </el-menu>

        <div style="position: fixed; bottom: 60px; width: 208px; text-align: left; padding-left: 30px;">
          <ul style="cursor: pointer">
            <li style="font-size: 14px" @click="gotoHelpOpenTutorial()">新手教程</li>
            <li style="font-size: 14px; margin-top: 20px" @click="gotoHelp()">{{$t('footer.ul_li2')}}</li>
            <li style="margin-top: 20px; width: 153px; word-break: break-word;">
              <a style="display: inline-block;color: #464255;  word-break: break-word;font-size: 14px" target="_blank" :href="$registered_address.okx_address()"><span style="font-size: 14px;color: #000000">{{$t('footer.ul_li3')}}</span><br/><span style="display: block;margin-top: 0px">({{ $registered_address.okx_address() }})</span></a>
              <span style="display: block;cursor: pointer; color: #AB54DB;border-bottom: #AB54DB 1px solid;line-height: 14px; width: fit-content;margin-top: 5px; font-size: 14px" @click="copy()">{{ $t('alter.copy') }}</span>
            </li>
          </ul>
        </div>
      </el-aside>

      <el-container>
        <el-main>
          <keep-alive>
            <router-view v-if="$route.meta.keepAlive"></router-view>
          </keep-alive>
          <router-view v-if="!$route.meta.keepAlive" @updateInfo="getUserInfo"></router-view>
        </el-main>
<!--        <el-footer>-->
<!--          <Footer_lg @showFeedBack="openMessageBack"></Footer_lg>-->
<!--        </el-footer>-->
      </el-container>
    </el-container>
<!--      <div class="sm" v-if="smShow">-->
<!--        <el-menu-->
<!--            v-if="this.$route.path!='/robot' && this.$route.path!='/week_list'&&$route.path!=='/earn_rank' && $route.path !='/feedBack'"-->
<!--            class="sm-menu"-->
<!--            :default-active="$route.path"-->
<!--            mode="horizontal"-->
<!--            text-color="#72777E"-->
<!--            router-->
<!--            active-text-color="#AB54DB">-->
<!--          <el-menu-item index="/user_center" style="text-align: center">-->
<!--            <img :src="require('../assets/img/center/user'+(this.$route.path==='/user_center' ? '_active' : '')+'.svg')" alt=""><span>用户中心</span>-->
<!--          </el-menu-item>-->
<!--          <el-menu-item index="/rebate"><img :src="require('../assets/img/center/rebate'+(this.$route.path==='/rebate' ? '_active' : '')+'.svg')" alt=""><span>返佣中心</span></el-menu-item>-->
<!--          <el-menu-item index="/safe"><img :src="require('../assets/img/center/safe'+(this.$route.path==='/safe' ? '_active' : '')+'.svg')" alt=""><span>安全中心</span></el-menu-item>-->
<!--        </el-menu>-->
<!--        <div v-if="$route.path!='/week_list' && $route.path !='/feedBack' && $route.path.indexOf('/semi_detail') == -1 &&  $route.path.indexOf('/auto_detail') == -1" class="add-static">-->
      <div class="sm" v-if="smShow" style="position: relative" >

        <div v-if="$route.path!='/week_list' && $route.path !='/feedBack' && $route.path !='/messageBack' && ($route.path.indexOf('/user/')==-1 || $route.path == '/user/safe') && $route.path.indexOf('/semi_detail') == -1 &&  $route.path.indexOf('/auto_detail') == -1 && $route.path.indexOf('/martin_add') == -1 && $route.path.indexOf('/martin_detail') == -1 && $route.path.indexOf('/profit_details') == -1 && $route.path.indexOf('/profit_details_every') == -1 && $route.path.indexOf('/contract_detail') == -1" class="add-static">
          <div style="display: none"></div>
          <div @click="$router.push('/user_center')">
            <img :src="require('../assets/img/center/income'+(this.$route.path==='/user_center' ? '_active' : '')+'.svg')" alt="">
            <div :style="{color:this.$route.path==='/user_center' ? '#AB54DB' :'#72777E'}">{{$t('center.sm_tab1')}}</div>
          </div>
          <div @click="$router.push('/robot')">
            <img :src="require('../assets/img/center/robot'+(this.$route.path==='/robot' ? '_active' : '')+'.svg')" alt="">
            <div :style="{color:this.$route.path==='/robot' ? '#AB54DB' :'#72777E'}">{{$t('center.sm_tab2')}}</div>
          </div>
<!--          <div @click="$router.push('/rebate')">-->
<!--            <img :src="require('../assets/img/center/rebate'+(this.$route.path==='/rebate' ? '_active' : '')+'.svg')" alt="">-->
<!--            <div :style="{color:this.$route.path==='/rebate' ? '#AB54DB' :'#72777E'}">{{$t('center.sm_tab3')}}</div>-->
<!--          </div>-->
          <div @click="$router.push('/user/safe')">
            <img :src="require('../assets/img/center/safe'+(this.$route.path==='/user/safe' ? '_active' : '')+'.svg')" alt="">
            <div :style="{color:this.$route.path==='/user/safe' ? '#AB54DB' :'#72777E'}">{{$t('center.sm_tab4')}}</div>
          </div>
        </div>
        <div class="add-menu" id="add-menu" v-show="$route.path=='/user/personal' || $route.path=='/user/property' || $route.path=='/user/member' || $route.path=='/user/apiPage'" style="position: absolute;top: 0;z-index: 100;width: 100%;">
          <div @click.stop="handleCutMenu" style="height: 100%">
            <el-menu
                ref="menu"
                class="sm-menu"
                style="width: 100%"
                :default-openeds="['']"
                :default-active="$route.meta.tabName"
                mode="vertical"
                @select="handleSelectDrawer"
                text-color="#000613"
                router
                :collapse-transition="false"
                active-text-color="#AB54DB">
              <el-submenu index="userMenu">
                <template slot="title">
                  <span v-show="$route.path=='/user/property'">{{$t('center.safe_tab1')}}</span>
                  <span v-show="$route.path=='/user/member'">{{$t('center.safe_tab2')}}</span>
                  <span v-show="$route.path=='/user/personal'">{{$t('center.safe_tab3')}}</span>
                  <span v-show="$route.path=='/user/safe'">{{$t('center.safe_tab4')}}</span>
<!--                  <span v-show="$route.path=='/user/apiPage'">{{$t('center.safe_tab5')}}</span>-->
                </template>
                <el-menu-item-group>
                  <el-menu-item @click="handleCutItemMenu" index="/user/property">{{$t('center.safe_tab1')}}</el-menu-item>
<!--                  <el-menu-item @click="handleCutItemMenu" index="/user/member">{{$t('center.safe_tab2')}}</el-menu-item>-->
                  <el-menu-item @click="handleCutItemMenu" index="/user/personal">{{$t('center.safe_tab3')}}</el-menu-item>
                  <el-menu-item @click="handleCutItemMenu" index="/user/safe">{{$t('center.safe_tab4')}}</el-menu-item>
<!--                  <el-menu-item @click="handleCutItemMenu" index="/user/apiPage">{{$t('center.safe_tab5')}}</el-menu-item>-->
                </el-menu-item-group>
              </el-submenu>
            </el-menu>
          </div>
        </div>
        <div v-if="$route.path.indexOf('/user/')!=-1 && $route.path != '/user/safe'" style="height: 56px"></div>
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive" @updateInfo="getUserInfo"></router-view>
        <Footer_sm v-if="$route.path!='/robot/martin_add' && $route.path !='/messageBack'"></Footer_sm>
      </div>

<!--      抽屉-->
      <el-drawer
          size="100%"
          :visible.sync="drawer"
          :show-close="true">
        <div class="drawer">
          <el-menu
              class="sm-menu"
              :default-openeds="['']"
              :default-active="$route.path"
              mode="vertical"
              @select="handleSelectDrawer"
              text-color="#000613"
              router
              active-text-color="#AB54DB">
            <div class="user-icons-info" style="margin: 0px 0 10px 0">
              <img v-if="userInfo.user_face=='' || userInfo.user_face==null" style="border-radius: 50%" src="../assets/img/avatar.png" alt="" @click="showImage()" >
              <img v-else style="border-radius: 50%" :src="userInfo.user_face" alt="" @click="showImage(userInfo.user_face)">
<!--              <pop_image style="" v-if="userInfo.user_face=='' || userInfo.user_face==null" :imgUrl="''" :placement="'bottom'" header="'header'" alt=""/>-->
<!--              <pop_image style="" v-else :imgUrl="userInfo.user_face" :placement="'bottom'" header="'header'" alt=""/>-->
              <div>{{username}}</div>
<!--              <img style="cursor: pointer" @click="$router.push('/grant_member')"  v-if="userInfo.charge_style2==3" src="../assets/img/ssvip.svg" alt="">-->
<!--              <img style="cursor: pointer" @click="$router.push('/grant_member')" v-else-if="userInfo.charge_style2==2" src="../assets/img/SVIP.svg" alt="">-->
<!--              <img style="cursor: pointer" @click="$router.push('/grant_member')" v-else src="../assets/img/VIP.svg" alt="">-->
<!--              <img v-if="userInfo.charge_style2 == 2 || userInfo.charge_style2 == 3" style="margin-left: 5px" src="../assets/img/user/discount.svg">-->
            </div>
            <el-menu-item index="/user_center"><img style="width: 24px;height: 24px;margin-right: 6px" :src="require('../assets/img/center/drawer_user'+(this.$route.path==='/user_center' ? '_active' : '')+'.svg')" alt=""><span>{{$t('center.drawer_tab1')}}</span></el-menu-item>
            <el-menu-item v-if="this.$route.path != '/robot/semi_detail' && this.$route.path != '/robot/auto_detail' && this.$route.path != '/robot/martin_detail'" index="/robot"><img style="width: 24px;height: 24px;margin-right: 6px" :src="require('../assets/img/center/drawer_robot'+((this.$route.path==='/robot') ? '_active' : '')+'.svg')" alt=""><span :style="{color:this.$route.path==='/robot' ? '#AB54DB' :'unset'}">{{$t('center.drawer_tab2')}}</span></el-menu-item>
            <el-menu-item v-if="this.$route.path == '/robot/semi_detail'" index="/robot/semi_detail"><img style="width: 24px;height: 24px;margin-right: 6px" :src="require('../assets/img/center/drawer_robot'+((this.$route.path === '/robot/semi_detail') ? '_active' : '')+'.svg')" alt=""><span :style="{color:this.$route.path==='/robot/semi_detail' ? '#AB54DB' :'unset'}">{{$t('center.drawer_tab2')}}</span></el-menu-item>
            <el-menu-item v-if="this.$route.path == '/robot/auto_detail'" index="/robot/auto_detail"><img style="width: 24px;height: 24px;margin-right: 6px" :src="require('../assets/img/center/drawer_robot'+((this.$route.path === '/robot/auto_detail') ? '_active' : '')+'.svg')" alt=""><span :style="{color:this.$route.path==='/robot/auto_detail' ? '#AB54DB' :'unset'}">{{$t('center.drawer_tab2')}}</span></el-menu-item>
            <el-menu-item v-if="this.$route.path == '/robot/martin_detail'" index="/robot/auto_detail"><img style="width: 24px;height: 24px;margin-right: 6px" :src="require('../assets/img/center/drawer_robot'+((this.$route.path === '/robot/auto_detail') ? '_active' : '')+'.svg')" alt=""><span :style="{color:this.$route.path==='/robot/auto_detail' ? '#AB54DB' :'unset'}">{{$t('center.drawer_tab2')}}</span></el-menu-item>
<!--            <el-menu-item index="/rebate"><img style="width: 24px;height: 24px;margin-right: 6px" :src="require('../assets/img/center/drawer_rebate'+(this.$route.path==='/rebate' ? '_active' : '')+'.svg')" alt=""><span>{{$t('center.drawer_tab3')}}</span></el-menu-item>-->
<!--            <el-menu-item index="/grant_member"><img style="width: 24px;height: 24px;margin-right: 6px" :src="require('../assets/img/center/drawer_income'+(this.$route.path==='/grant_member' ? '_active' : '')+'.svg')" alt=""><span>{{$t('center.drawer_tab4')}}</span></el-menu-item>-->
<!--            <el-menu-item index="/safe"><img style="width: 24px;height: 24px;margin-right: 6px" :src="require('../assets/img/center/drawer_safe'+(this.$route.path==='/safe' ? '_active' : '')+'.svg')" alt=""><span>安全中心</span></el-menu-item>-->
            <el-submenu index="1">
              <template slot="title">
                <img style="width: 24px;height: 24px;margin-right: 6px" :src="require('../assets/img/center/drawer_safe'+(this.$route.path==='/safe' ? '_active' : '')+'.svg')">
                <span>{{$t('center.center')}}</span>
              </template>
              <el-menu-item-group style="margin-top: -20px">
                <el-menu-item index="/user/property">{{$t('center.safe_tab1')}}</el-menu-item>
<!--                <el-menu-item index="/user/member">{{$t('center.safe_tab2')}}</el-menu-item>-->
                <el-menu-item index="/user/personal">{{$t('center.safe_tab3')}}</el-menu-item>
                <el-menu-item index="/user/safe">{{$t('center.safe_tab4')}}</el-menu-item>
<!--                <el-menu-item index="/user/apiPage">{{$t('center.safe_tab5')}}</el-menu-item>-->
              </el-menu-item-group>
            </el-submenu>
            <el-menu-item @click="handleToExit"><img style="width: 24px;height: 24px;margin-right: 6px" :src="require('../assets/img/center/drawer_exit'+(this.$route.path==='/exit' ? '_active' : '')+'.svg')" alt=""><span :style="{color:$route.path==='/exit' ? '#AB54DB' :'#303133'}">{{$t('center.exit')}}</span></el-menu-item>
          </el-menu>
        </div>
      </el-drawer>
      <Remind_pay></Remind_pay>
      <luck-draw v-if="isShowLuck"></luck-draw>
<!--      <luck-draw ></luck-draw>-->

      <el-dialog
          :visible.sync="isShowDueSoon"
          title=""
          :center="true"
          :close-on-click-modal="false"
          width="318px"
          :show-close="false"
          class="dialog2">
        <div style="text-align: center">
          <img style="width: 80px;margin: 0 auto; display: block" src="../assets/img/luckDraw/due_soon.png" alt="pointer">
          <span style="display: block;color: black;font-size: 24px; font-weight: 500;margin-top: 24px">免燃料費政策即將到期</span>
          <span style="display: block;color:#555452;margin-top: 16px">您的免燃料費政策將於{{ dueSoon }}到期，到期資產餘額不足將自動關停機器人</span>
          <button style="background-color: #FAAD14;width: 100%;padding: 10px 0;border: none;border-radius: 2px;color: white;font-size: 16px;margin-top: 24px" @click="go_property()">去充值</button>
          <span style="display: block;width: fit-content; margin: 20px auto 0;color: #FAAD14; border-bottom: 1px #FAAD14 solid" @click="i_know()">下次再説</span>
        </div>
      </el-dialog>

      <el-dialog
          :visible.sync="isShowExpired"
          title=""
          :center="true"
          :close-on-click-modal="false"
          width="318px"
          :show-close="false"
          class="dialog2">
        <div style="text-align: center">
          <img style="width: 80px;margin: 0 auto; display: block" src="../assets/img/luckDraw/expired.png" alt="pointer">
          <span style="display: block;color: black;font-size: 24px; font-weight: 500;margin-top: 24px">免燃料費政策已到期</span>
          <span style="display: block;color:#555452;margin-top: 16px">您的免燃料費政策已於{{ dueSoon }}到期，資產餘額不足將自動關停機器人</span>
          <button style="background-color: #FF4D4F;width: 100%;padding: 10px 0;border: none;border-radius: 2px;color: white;font-size: 16px;margin-top: 24px" @click="go_property()">去充值</button>
          <span style="display: block;width: fit-content; margin: 20px auto 0;color: #FF4D4F; border-bottom: 1px #FF4D4F solid" @click="i_know2()">下次再説</span>
        </div>
      </el-dialog>

      <el-dialog
          :visible.sync="showcode"
          title=""
          :center="true"
          :close-on-click-modal="true"
          width="315px"
          :show-close="true"
          class="dialog_down">
        <div v-if="!this.$util._isMobile()" style="text-align: center; border-radius: 20px">
          <img style="width: 200px; height: 200px; margin: 0 auto; display: block" src="../assets/img/download_code.png" alt="pointer">
          <span style="font-size: 20px;margin-top: 30px;display: block">扫描二维码下载</span>
        </div>
<!--        <div v-if="this.$util._isMobile()" style="width: 226px; margin: 0 auto;text-align: center; vertical-align: middle">-->
<!--          <div style="background: #AB54DB; border-radius: 10px;padding: 10px 30px;" @click="download_android()">-->
<!--            <img style="display: inline-block;vertical-align: middle; width: 36px" src="../assets/img/android.png">-->
<!--            <span style="font-size: 20px; color: #FFFFFF; margin-left: 15px;display: inline-block;vertical-align: middle">下载Android</span>-->
<!--          </div>-->
<!--          <div style="background: #7F848C; border-radius: 10px; margin-top: 100px;padding: 10px 30px">-->
<!--            <img style="display: inline-block;vertical-align: middle; width: 36px" src="../assets/img/iOS.png">-->
<!--            <span style="font-size: 20px; color: #FFFFFF; margin-left: 15px;display: inline-block;vertical-align: middle">IOS即将上线</span>-->
<!--          </div>-->
<!--        </div>-->

      </el-dialog>

      <div :class="msgCount > 0?'shake':''" v-if="$route.path !='/messageBack' && $route.path != '/robot/martin_add' && !showMessage" class="message_icon" style="border-radius: 50%;cursor: pointer" @click="gotoMessage()">
        <div v-if="msgCount > 0" style="position:absolute;left:0;top:0;background-color: #FF4D4F;width: 10px;height: 10px;border-radius: 50%"></div>
        <img src="../assets/img/message.svg" style="display: block;width: 23px; margin: 10px auto 0;"></img>
      </div>
      <el-drawer
          class="message-bax"
          :title="$t('footer.ul_li1')"
          :visible.sync="showMessage"
          :modal="false"
          :before-close="handleClose"
          :direction="direction">
        <div style="width: 100%;height: 1px;background: #EAECEF;margin: 10px 3% 10px 0"></div>
        <div style="background-color: white; position: relative;height: 96.6%">
          <div class="message-bax-top" id="message-content" style="overflow: auto" ref="viewBox">
            <div style="font-size: 14px" v-for="(item, i) in messageList">
              <div style="position:relative;padding:0 20px">
                <span v-if="i== 0 || (i != 0 && (item.create_this_time-messageList[i-1].create_this_time)>1000*60*60*2)" style="display: block; width: 100%; text-align: center;margin-top: 10px;font-size: 12px;color: #72777E">{{ item.create_time }}</span>
                <div v-if="item.type =='user'" style="width: 100%; text-align: -webkit-right;margin-top: 10px">
                  <div style="display: inline-block; width: 76%;">
                    <div v-if="item.content != 'pic_info'" style="display: block;max-width: 100%;width: fit-content;background-color: #E0EBFF;padding: 15px;border-radius: 10px;margin-bottom: 10px">
                      <span style="display:block; width:100%; text-align: left;word-break: break-all;color: #1E2329" v-html="item.content"></span>
                    </div>
                    <img v-if="item.picture" style="display:block;width: 100px;border-radius: 10px" :src="item.picture_small" @click="preview(item.picture)">
                  </div>
                  <img v-if="user_header" style="width: 35px; height: 35px; border: #AB54DB 1px solid; border-radius: 50%;margin-left: 6px;display: inline-block;vertical-align: top" :src="user_header">
                  <img v-else style="width: 35px;border: #AB54DB 1px solid; border-radius: 50%;margin-left: 6px;display: inline-block;vertical-align: top" src="../assets/img/avatar.png">
                </div>
<!--                <span v-if="item.update_time" style="display: block; width: 100%; text-align: center;margin-top:10px;font-size: 12px;color: #72777E">{{ item.update_time }}</span>-->
                <div v-if="item.type == 'service'" style="display:block; width:100%; text-align: left;margin-top: 10px">
                  <img style="width: 35px;border: #AB54DB 1px solid; border-radius: 50%;display: inline-block;margin-right: 6px;vertical-align: top" src="../assets/img/msg_header.jpg">
                  <div style="display: inline-block;width: 76%">
                    <span style="display: block;vertical-align: top;word-break: break-all;width: fit-content;background-color: #f7f7f7;padding: 15px;border-radius: 10px;color: #1E2329">{{ item.content?item.content=='^^^&&&***###'?'你好！我是规划师，很高兴为你服务。请用一两句话简述你的问题。':item.content:'' }}</span>
                    <img v-if="item.replyPictureList" style="display: block;width: 100px;margin-top: 10px; border-radius: 10px;" v-for="items in item.replyPictureList" :src="items + '/small'" @click="preview(items)">
                  </div>
<!--                  <span style="display: inline-block;vertical-align: top;word-break: break-all;max-width: 70%;background-color: #f7f7f7;padding: 15px;border-radius: 10px;color: #1E2329">{{ item.reply }}</span>-->
                </div>
<!--                <text v-if="i == messageList.length -1 && showTimeNoOnline == true" style="display: block; width: 100%; text-align: center;margin-top: 10px;font-size: 12px;color: #ffffff">{{$t('index.Customer_not_online')}}</text>-->
                <div v-if="(messageList.length == 0 || i == messageList.length -1) && showTimeNoOnline == true" style="display:block; width:100%; text-align: left;margin-top: 10px">
                  <img style="width: 35px;border: #AB54DB 1px solid; border-radius: 50%;display: inline-block;margin-right: 6px;vertical-align: top" src="../assets/img/msg_header.jpg">
                  <div style="display: inline-block;width: 76%">
                  <span style="display: block;vertical-align: top;word-break: break-all;width: fit-content;background-color: #f7f7f7;padding: 15px;border-radius: 10px;color: #1E2329;-webkit-user-select: text; user-select: text;">客服上班时间是：
                    ☀️上午09：00-12:00
                    🌙下午13：00-18:00

                    有任何问题您可以在这个时间段联系我们🌷~
                    非在班时间的话可以先留言，上班后也会第一时间回复您的呢❤️
                    祝您身体健康，工作顺利，财源滚滚👍</span>
                  </div>
                </div>
<!--                <span v-if="i == messageList.length -1 && !item.reply" style="display: block; width: 100%; text-align: center;margin-top: 10px;font-size: 12px;color: #72777E">感谢您的反馈，我们将于1个工作日内回复您！</span>-->
              </div>
            </div>
          </div>

          <div class="message-bax-bottom" style="position:relative;">
<!--            <div style="width: 100%;padding: 10px 0;border-bottom: #EAECEF 1px solid; margin-bottom: 10px;">-->
<!--              <el-radio style="margin: 5px 0px 5px 20px" v-model="selectValue" label="投诉" border size="medium">投诉</el-radio>-->
<!--              <el-radio v-model="selectValue" label="建议" border size="medium">建议</el-radio>-->
<!--              <el-radio v-model="selectValue" label="故障" border size="medium">故障</el-radio>-->
<!--              <el-radio v-model="selectValue" label="其他" border size="medium">其他</el-radio>-->
<!--            </div>-->

            <div style="position: relative">
              <div style="display:inline-block;vertical-align: middle">
                <el-upload
                    class="avatar-uploader"
                    :action="upload"
                    name="pic"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload"
                    :on-preview="handlePictureCardPreview">
                  <div class="" style="text-align: center;">
                    <img style="width: 30px; height: 28px; text-align: center" src="../assets/img/msg_pic.svg">
                  </div>
                </el-upload>
              </div>
              <el-input style="display:inline-block;font-size: 14px;border: unset;width: 60%;vertical-align: middle;" :autosize="{ minRows: 1, maxRows: 3 }" type="textarea" :placeholder="$t('robot.enter_content')" v-model="feedbackContent" @input="totalinput($event)"></el-input>
              <el-button class="submit-btn" type="primary" style="" @click="submit()">{{ $t('footer.send') }}</el-button>
            </div>
          </div>
        </div>
<!--        <iframe style="height: 100%; width: 100%;" src="https://chatbot.aliyuncs.com/intl/index.htm?from=4qOUAx1INE" name="obj" frameborder="0"></iframe>-->
      </el-drawer>
      <el-image-viewer v-if="imgsVisible" :urlList="[showImgUrl]" :on-close="closeViewer" style="z-index: 2022"></el-image-viewer>
      <div id="dialog" v-if="isShowLiveView" style="position: fixed; right: 50px; top: 100px; z-index: 9999;cursor: pointer" @click="gotoLiveView()">
        <div style="background: #C3ACD7; border-radius: 12px; height: 46px; width: 125px; text-align: center;">
          <img style="width: 30px; margin-top: -50%; height: 30px;" src="../assets/img/icon/logo_little.png" mode=""></img>
          <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -40%); width: 100%;">
            <span style="color: #2B2828; font-size: 14px; display: inline-block; vertical-align: middle; font-weight: 600;">UPLUS直播</span>
            <img style="width: 16px; height: 16px; display: inline-block; vertical-align: middle; margin-left: 5px; border-radius: 3px;" src="../assets/img/icon/living.gif"></img>
          </div>
        </div>
      </div>
  </el-container>
</template>

<script scoped>
import Footer_lg from "./footer/Footer_lg";
import Footer_sm from "./footer/Footer_sm";
import luckDraw from "../views/user_center/luckDraw";
import Remind_pay from "../views/grant_member/member_content/Remind_pay";
import {getUserInfo} from "../server/rebate";
import {getMemberRemainNum} from "../server/member";
import {getIsTestUser, getIsModuleTestUser} from "../server/robot";
import {Base64} from "js-base64";
import {getActivityById, getUserPolicy} from "../server/user";
import {baseUrl} from "../utils/request";
import {feedbackRequest, getFeedbackCount, getFeedbackMsg} from "../server/auth";
import { getNoticeMsgList } from "../server/helper.js"
import { getUnreadMsgCount } from "../server/message"
// 可自行去对应目录查看该组件
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import Pop_image from "../views/common/pop_image";
import {getActivityById_Other} from "../server/activity"
export default {
  name: "Navbar",
  data(){
    return {
      showCutMenu:false,
      drawer:false,
      lgShow:false,
      smShow:false,
    //  是否设置名字
      username:'',
    //  是否是vip
      isVip:false,
      userInfo:{},
      dueSoon: '',
      isShowLuck: false,
      isShowDueSoon: false,
      isShowExpired: false,
      isShowNotice1: false,
      visible: false,
      visibleSm: false,
      activity: 0,
      direction: 'btt',
      showMessage: false,
      selectValue: '其他',
      feedbackContent: '',
      imageUrl: '',
      thisNum: 0,
      totalNum: 500,
      upload: baseUrl + '/other/uploadImage',
      messageList: [],
      showImgUrl: '',
      imgsVisible: false,
      msgCount: 0,
      pageNum: 1,
      pageSize: 10,
      totalPage: 1,
      user_header: '',
      timer: null, // 定时器
      msgHeight: 0,
      showcode: false,
      lastTime: '',
      firstTime: '',
      uid: '',
      showTimeNoOnline: false, // 为true时 提示客服不在线
      selectLanguage: '',
      noticeMsgList: [],
      noticeNums: 0,
      token: '',
      isShowLiveView: false,
      liveActivityInfo: {},
      showCardActivity: false
      // memberInfo:{
      //   duration_type:1
      // }
    }
  },

  created() {
    this.changeScreenSize();
    // this.getIsTestUser();
    this.getIsModuleTestUser();
    // sessionStorage.setItem('testUser',Base64.encode('true')) // 是否启动内测用户
    this.selectLanguage = localStorage.getItem('language')
    this.token = localStorage.getItem('token')
    this.livingView(10)
    // $("#dialog").dialog();
  },
  mounted() {
    this.changePage()
    // this.changeOffset()
    // this.getMemberRemain()
    this.getActivityById()
    // if (localStorage.getItem('isShowDueSoon')){
    //   if (localStorage.getItem('isShowDueSoon') == '1'){
    //     this.isShowDueSoon = false;
    //     return;
    //   }
    // }
    // if (localStorage.getItem('isShowExpired')){
    //   if (localStorage.getItem('isShowExpired' == '1')){
    //     this.isShowExpired = false;
    //     return;
    //   }
    // }
    var thisTimes = new Date().getTime()
    if(thisTimes>=1703865600000 && thisTimes<1704643200000) {
      this.showCardActivity = true
    } else {
      this.showCardActivity = false
    }
    this.getUserPolicy();
    this.getFeedbackCount(10)
    this.messageList = []
    this.index = this.$route.query.showMessage
    // if (this.$route.query.showMessage) {
    //   this.showMessage = JSON.parse(this.$route.query.showMessage)
    //   this.getMessageInfo()
    // }
    var that = this
    if (this.$store.state.showFeedBack == true){
      this.$store.state.showFeedBack = false
      this.showMessage = true
      if(new Date().getHours()>18 || new Date().getHours()<9){
        this.showTimeNoOnline = true
      }
      this.getMessageInfo();
      this.$nextTick(()=>{
        this.scrollListener()
      })
    }
    if(!this.token){
      const fpPromise = new Promise((resolve, reject) => {
        const script = document.createElement('script')
        script.onload = resolve
        script.onerror = reject
        script.async = true
        script.src = 'https://cdn.jsdelivr.net/npm/' + '@fingerprintjs/fingerprintjs@3/dist/fp.min.js'
        document.head.appendChild(script)
      }).then(() => FingerprintJS.load())
      // Get the visitor identifier when you need it.
      fpPromise.then(fp => fp.get()).then(result => {
        // This is the visitor identifier:
        const visitorId = result.visitorId
        // console.log("test>>>>>>:"+visitorId)
        this.uid = visitorId
        // that.getMessageInfo();
      })
    } else {
      this.uid = ''
      // this.getMessageInfo()
    }
    this.getUnreadMsgCount()
    this.getNoticeMsgList()
  },
  computed:{
    userNavName(){
      const {name,mobile,username}=this.userInfo?this.userInfo:{}
      if (name!==''&&name!==null) return name
      else if(mobile!==null&&mobile!=='') return mobile
      else return username
    },
  },

  activated() {
    this.getUnreadMsgCount()
    this.getFeedbackCount(10)
  },

  methods:{
    //會員有效期的接口
    // async getMemberRemain(){
    //   const memberInfo= await getMemberRemainNum()
    //   if (memberInfo.data==null) return
    //   this.memberInfo=memberInfo.data
    // },

    async getIsTestUser(){// 该用户是否为内测用户
      const res = await getIsTestUser()
      if (!res) return;
      if (res.success){
        // if (res.data){
          sessionStorage.setItem('testUser',Base64.encode(res.data.toString()))
        // }
      }
    },

    async getIsModuleTestUser(){// 该用户是否为内测用户
      const res = await getIsModuleTestUser()
      if (!res) return;
      if (res.success){
        // if (res.data){
          sessionStorage.setItem('testUser',Base64.encode(res.data.toString()))
        // }
      }
    },

    handleAside(e){
       document.documentElement.scrollTop = e;
      // console.log(document.getElementsByClassName('menu-vertical .el-menu-item.is-active'));
    },
    changeOffset(id='robot-earn'){
      window.addEventListener('scroll',()=>{
        // console.log(document.documentElement.scrollTop);
        // const target= document.getElementById(id).getBoundingClientRect()
        // console.log(target.top);
      })
    },
    changeScreenSize(){
      window.addEventListener('resize',()=>{
        const bodyWidth=document.body.clientWidth
        if (bodyWidth>=1079) {
          this.lgShow = true
          this.smShow=false
          return
        }
        this.smShow=true
        this.lgShow=false
      })
      const bodyWidth=document.body.clientWidth
      if (bodyWidth>=1079) return this.lgShow = true
      this.smShow=true
    },
    changePage(){
      this.$nextTick(()=>{
        if (this.$refs.menu) this.$refs.menu.close('userMenu')
        if (document.getElementById('add-menu')){
          document.getElementById('add-menu').style.background = 'unset'
          document.getElementById('add-menu').style.height='unset'
        }
      })
      this.getUserInfo()
      this.getFeedbackCount(10)
      const bodyWidth=document.body.clientWidth
      if (bodyWidth<1079) return
      if (this.$route.path==='/rebate') document.getElementsByClassName('el-main')[0].style.padding=0
      else document.getElementsByClassName('el-main')[0].style.padding='20px'
    },
    torobot(){
      this.$router.push('/robot')
    },
    handleSelectDrawer(){
      this.drawer=false
    },
  //  执行退出登录
    handleToExit(){
      window.localStorage.removeItem('token')
      // window.localStorage.removeItem(JSON.parse(localStorage.getItem('userInfo')).id+'\'1-1-1')
      window.localStorage.removeItem('userInfo')
      this.$router.replace('/')
      this.$message.success(this.$t('center.exit_login_success'))
    },
  //  获取用户信息
     async getUserInfo(){
      const userInfo=window.localStorage.getItem('userInfo')
      this.userInfo=JSON.parse(userInfo)
      if (userInfo) {
        const {name,mobile,charge_style2,username} = JSON.parse(userInfo)
        name===null||name===''? this.username=mobile:this.username=name
        if ((mobile===null||mobile==='')&&(name===null||name==='')) this.username=username
        charge_style2===2 ?this.isVip=false :this.isVip=true
        this.user_header = this.userInfo.user_face?this.userInfo.user_face + '/head':''
      }
    },
  //  切換菜單欄的操作
    handleCutMenu(){
      if (!this.showCutMenu) {
        document.getElementById('add-menu').style.background = 'rgba(0, 0, 0, 0.4)'
        document.getElementById('add-menu').style.height='100%'
      }
      if (this.showCutMenu) {
        this.$nextTick(()=>{
          this.$refs.menu.close('userMenu')
        })
        document.getElementById('add-menu').style.background = 'unset'
        document.getElementById('add-menu').style.height='unset'
      }
      this.showCutMenu=!this.showCutMenu
    },
    handleCutItemMenu(){
       this.$refs.menu.close('userMenu')
    },
    handleMouseEnterMenu(e){
      if (e==1) document.querySelector('.menuImg >div:nth-child(1) img').src=require('../assets/img/menu/menu_wallet_pre.svg')
      // else if (e==2) document.querySelector('.menuImg >div:nth-child(2) img').src=require('../assets/img/menu/menu_income_pre.svg')
      else if (e==3) document.querySelector('.menuImg >div:nth-child(2) img').src=require('../assets/img/menu/menu_user_pre.svg')
      else if (e==4) document.querySelector('.menuImg >div:nth-child(3) img').src=require('../assets/img/menu/menu_safe_pre.svg')
      else document.querySelector('.menuImg >div:nth-child(4) img').src=require('../assets/img/menu/menu_exit_pre.svg')
    },
    handleMouseLeaveMenu(e){
      if (e==1) document.querySelector('.menuImg >div:nth-child(1) img').src=require('../assets/img/menu/menu_wallet.svg')
      // else if (e==2) document.querySelector('.menuImg >div:nth-child(2) img').src=require('../assets/img/menu/menu_income.svg')
      else if (e==3) document.querySelector('.menuImg >div:nth-child(2) img').src=require('../assets/img/menu/menu_user.svg')
      else if (e==4) document.querySelector('.menuImg >div:nth-child(3) img').src=require('../assets/img/menu/menu_safe.svg')
      else document.querySelector('.menuImg >div:nth-child(4) img').src=require('../assets/img/menu/menu_exit.svg')
    },
    handleDrawerSwitch(){
      this.drawer = true
      if (this.showCutMenu) {
        this.showCutMenu=false
        this.$nextTick(()=>{
          this.$refs.menu.close('userMenu')
          document.getElementById('add-menu').style.background = 'unset'
          document.getElementById('add-menu').style.height='unset'
        })
      }

    },
    async getActivityById() { // 获取活动信息
      const res = await getActivityById(1)
      if (res){
        if (res.success){
          if(!res.data) return
          var date = res.data.start_time
          var endDate = res.data.end_time
          date = date.replaceAll('-', '/')
          endDate = endDate.replaceAll('-','/')
          if ((new Date()).getTime()>= (new Date(date)).getTime() && (new Date()).getTime()<= (new Date(endDate)).getTime()){
            var userInfo = JSON.parse(localStorage.getItem('userInfo')+'')
            if (userInfo.charge_style != 1){
              this.isShowLuck = true
            }
          }else{
            this.isShowLuck = false
          }
        }else {
          this.isShowLuck = false
          this.$message.warning(res.message)
        }
      }
    },
    async getUserPolicy(){// 燃料费即将到期提示
      const res = await getUserPolicy()
      if (res){
        if (res.success){
          if (!res.data) return
          this.activity = res.data.activity
          var date = res.data.update_time
          date = date.replaceAll('-', '/')
          this.dueSoon = this.$util.formatDate_c(date, '.');
          // localStorage.setItem('updateTime', this.dueSoon)
          this.resetSetItem('updateTime', this.dueSoon);
          if (0 <= (parseFloat(new Date(date).getTime()) - parseFloat(new Date().getTime())) && (parseFloat(new Date(date).getTime()) - parseFloat(new Date().getTime())) <= 1000*60*60*24){
            if(this.activity == 1){
              this.isShowDueSoon = true
            }
          } else if((parseFloat(new Date(date).getTime()) < parseFloat(new Date().getTime())) && (parseFloat(new Date().getTime()) - parseFloat(new Date(date).getTime()) < 1000*60*60*24)){
            if(this.activity == 1){
              this.isShowExpired = true;
            }
          } else if((parseFloat(new Date().getTime()) - parseFloat(new Date(date).getTime()) > 1000*60*60*24)){
            this.isShowExpired = false;
          }
        }else{
          this.$message.warning(res.message)
          this.isShowDueSoon = false
        }
      }
    },
    download_android(){
      let a = document.createElement('a')
      a.href ="https://felton-oss.oss-cn-beijing.aliyuncs.com/file/download/felton-app.apk"
      a.click();
    },
    i_know(){
      this.isShowDueSoon = false;
      localStorage.setItem('isShowDueSoon', '1')
    },
    i_know2(){
      this.isShowExpired = false
      localStorage.setItem('isShowExpired', '1')
    },
    go_property(){
      if (this.$route.path == '/user/property'){
        this.isShowDueSoon = false;
        this.isShowExpired = false;
      } else {
        this.$router.push('/user/property')
        this.isShowDueSoon = false;
        this.isShowExpired = false;
      }
    },
    showNotice(item){
      this.$router.push('/helpNoticeContent?titleNum=5_1_6&home=home&noticeId='+ item.id)
    },
    gotoLuckDraw(item){
      this.visible = false;
      this.visibleSm = false;
      this.showNotice(item)
      // this.resetSetItem('isShow', '1');
    },
    openMessageBack(data){ //pc端传参
      this.getMessageInfo();
      this.showMessage = data;
      this.timer = setTimeout(() => {
        this.scrollListener()
      }, 200)
    },
    gotoMessage(){
      if (this.$util._isMobile()){
        this.$router.push('/messageBack')
      } else {
        this.messageList = []
        this.firstTime = ''
        this.lastTime = ''
        if(new Date().getHours()>18 || new Date().getHours()<9){
          this.showTimeNoOnline = true
        }
        this.getMessageInfo();
        this.showMessage = true;
        this.timer = setTimeout(() => {
          this.scrollListener()
          // this.scrollBottom()
        }, 200)
      }
      this.msgCount = 0;
    },
    totalinput(value) {
      if (value.length > 500) {
        this.feedbackContent = value.substr(0, 500)
        this.$message.warning('最多只能輸入500個字符')
      }
      this.thisNum = this.feedbackContent.length
    },

    scrollListener(){
      this.box = this.$refs.viewBox
      this.box.addEventListener('scroll', this.addListener, false)
    },

    addListener(){
      if (this.$refs.viewBox.scrollTop == 0){
        // this.pageNum++
        if(this.messageList.length > 0){
          this.firstTime = this.messageList[0].time
          this.lastTime = ''
        } else {
          this.firstTime = ''
        }
        this.getMessageInfo(10)
      }
    },

    async submit(content) {
      if (this.token){
        this.uid = ''
      }
      if (!this.selectValue) {
        this.$message.warning('请选择问题类型')
        return
      }
      if (!this.feedbackContent) {
        this.$message.warning('请输入问题描述')
        return
      }
      if(this.$util.filterText(this.feedbackContent)){
        this.$message.warning('包含敏感词汇，请重新编辑！')
        return
      }
      const res = await feedbackRequest(this.selectValue, this.feedbackContent, this.imageUrl, this.uid, 10)
      if (res.success) {
        // if (!content){
        //   let info = {}
        //   info.content = this.feedbackContent.replaceAll('\n', '<br/>')
        //   info.create_time = this.$util.formatDateTime(this.$util.formatDate_dd(new Date().getTime(),'/'))
        //   info.create_this_time = new Date().getTime()
        //   this.messageList.push(info)
        // }
        if(this.messageList.length > 0){
          this.lastTime = this.messageList[this.messageList.length - 1].time
          this.firstTime = ''
        } else {
          this.lastTime = ''
        }
        await this.getMessageInfo(10, 'Refresh', 'addMessage')
        // this.selectValue='';
        this.feedbackContent='';
        this.imageUrl='';
        // this.scrollBottom();
        // await this.getMessageInfo(10);
      } else {
        this.$message.error(res.message);
      }
    },
    scrollBottom(){
      const that = this
      that.timer = setInterval(function () {
        // console.log(document.readyState)
        if (document.readyState === 'complete') {
          let div = document.getElementById('message-content');
          div.scrollTop = div.scrollHeight; // 将聊天框滚轮拉到最底部
          this.msgHeight = div.scrollHeight
          window.clearInterval(that.timer)
        }
      }, 800)
    },
    scrollCenter(){
      const that = this
      that.timer = setInterval(function () {
        // console.log(document.readyState)
        if (document.readyState === 'complete') {
          let div = document.getElementById('message-content');
          div.scrollTop = div.scrollHeight-this.msgHeight; // 将聊天框滚轮拉到最底部
          this.msgHeight = div.scrollHeight
          window.clearInterval(that.timer)
        }
      }, 500)
    },

    handleAvatarSuccess(res, file) {
      this.imageUrl = res.data;
      this.feedbackContent = 'pic_info'
      var info = {}
      info.picture = this.imageUrl
      info.picture_small = this.imageUrl+'/small'
      info.content = this.feedbackContent
      info.create_time = this.$util.formatDateTime(this.$util.formatDate_dd(new Date().getTime(),'/'))
      info.create_this_time = new Date().getTime()
      // this.messageList.push(info)
      this.submit('pic_info')
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isLt12M = file.size / 1024 / 1024 < 12;

      if (!isJPG && !isPNG) {
        this.$message.error('图片只能是 JPG或者PNG 格式!');
      }
      if (!isLt12M) {
        this.$message.error('图片大小不能超过 12MB!');
      }
      return (isPNG || isJPG) && isLt12M;
    },
    handlePictureCardPreview(file, fileList) {
      // console.log(12)
    },
    async getMessageInfo(requestTimes, Refresh, addMessage){
      if (!Refresh && (this.pageNum > this.totalPage)) {
        return
      }
      const res = await getFeedbackMsg(requestTimes, this.pageNum, this.pageSize, this.uid, this.lastTime, this.firstTime)
      if (res) {
        if (res.success) {
          if(!Refresh && !this.firstTime && !this.lastTime){
            this.totalPage = res.data.totalPage>0?res.data.totalPage:1
          }
          this.messageList_linshi = res.data.list
          for (let i = 0; i < this.messageList_linshi.length; i++) {
            this.messageList_linshi[i].content = this.messageList_linshi[i].content.replace('\n', '<br/>')
            this.messageList_linshi[i].create_this_time = this.$util.formatUnix(this.messageList_linshi[i].time)
            // this.messageList_linshi[i].update_this_time = this.$util.formatUnix(this.messageList_linshi[i].update_time)
            this.messageList_linshi[i].create_time = this.$util.formatDateTime(this.messageList_linshi[i].time)
            if (this.messageList_linshi[i].time) {
              this.messageList_linshi[i].update_time = this.$util.formatDateTime(this.messageList_linshi[i].time)
            }
            if (this.messageList_linshi[i].picture) {
              this.messageList_linshi[i].picture_small = this.messageList_linshi[i].picture + '/small'
            }

            if (this.messageList_linshi[i].picture && this.messageList_linshi[i].type == 'service'){
              this.messageList_linshi[i].replyPictureList = this.messageList_linshi[i].picture.split(',')
            }
          }
          // this.messageList = this.messageList_linshi.concat(this.messageList)
          if(Refresh || (addMessage && addMessage == 'addMessage')){
            this.messageList = this.messageList.concat(this.messageList_linshi)
          } else {
            this.messageList = this.messageList_linshi.concat(this.messageList)
          }

          if(Refresh || (!this.firstTime && !this.lastTime)){
            if(this.messageList.length > 0){
              this.lastTime = this.messageList[this.messageList.length - 1].time
            }
            this.scrollBottom()
          }
          // if (this.pageNum == 1){
          //   this.scrollBottom()
          // } else{
          //   this.scrollCenter()
          // }

        } else {
          this.$message.warning(res.message)
        }
      }
    },

    download_app(){
      if (this.$util._isMobile()){
        this.$router.push({name: 'download', query:{showBack: 1}})
      } else {
        this.$router.push({name: 'download', query:{showBack: 1}})
        // this.showcode = true
      }
    },

    preview(view){
      this.imgsVisible = true
      this.showImgUrl = view
      this.imageClick();
    },
    showImage(url){
      this.showImgUrl = url ? url : require("../assets/img/avatar.png")
      this.imgsVisible = true
      this.imageClick();
    },

    //添加图片的点击事件
    imageClick(){
      this.$nextTick(()=>{
        // 获取遮罩层dom
        let domImageMask = document.querySelector(".el-image-viewer__img");
        if (!domImageMask) {
          return;
        }
        domImageMask.addEventListener("click", () => {
          // 点击遮罩层时调用关闭按钮的 click 事件
          document.querySelector(".el-image-viewer__close").click();
        });
      })
    },

    closeViewer(){
      this.imgsVisible = false
    },
    async getFeedbackCount(requestTimes){
      const res = await getFeedbackCount(requestTimes)
      if (res){
        if (res.success){
          this.msgCount = res.data
        }
      }
    },
    handleClose(){
      this.$store.state.showFeedBack = false
      this.showMessage = false
      this.box.removeEventListener("scroll", this.addListener, false)
    },
    gotoHelp(){
      if (this.$route.path.indexOf('/help') < 0){
        localStorage.setItem('lastPath', this.$route.path)
      }
      this.$router.push('/help')
    },
    copy() {
      const input = document.createElement('input');
      document.body.appendChild(input);
      input.setAttribute('value', this.$registered_address.okx_address());
      input.select();
      if (document.execCommand('copy')) {
        document.execCommand('copy');
        this.$message.success(this.$t('rebate.copy_success'))
      }
      document.body.removeChild(input);
    },

    selectLang(e){
      // this.selectLanguage=e
      this.selectLanguage=e
      localStorage.setItem('language', e)
      e==='繁体中文' ? this.$i18n.locale='zh-tw' : e==='简体中文' ? this.$i18n.locale='zh' : this.$i18n.locale='en'
      location.reload()
    },

    getNoticeMsgList(){
      getNoticeMsgList().then(res =>{
        if(!res) return
        if(res.success){
          if (res.data.list){
            for (var i = 0; i < res.data.list.length; i++) {
              if (i<=1){
                this.noticeMsgList.push(res.data.list[i])
              }
            }
            for (var i = 0; i < this.noticeMsgList.length; i++) {
              this.noticeMsgList[i].date = this.noticeMsgList[i].send_time.split(' ')[0]
            }
          }
        } else {
          this.$message.warning(res.message)
        }
      })
    },
    gotoHelpOpenTutorial(){
      this.$router.push('../../helpOpenTutorial?lastPage=lastPage')
    },

    getUnreadMsgCount(){
      getUnreadMsgCount(0, 10).then(res =>{
        if(!res) return
        if(res.success){
          this.noticeNums = res.data
          if(res.data > 0){
            this.isShowNotice1 = true
          } else {
            this.isShowNotice1 = false
          }
        } else {
          this.$message.warning(res.message)
        }
      })
    },

    livingView(hideLoad){
      getActivityById_Other(17, hideLoad).then(res=>{
        if(!res) return
        if(res.success){
          if (res.data){
            this.liveActivityInfo = res.data
            this.isShowLiveView = true
          } else {
            this.isShowLiveView = false
          }
        } else {
          this.$message.warning(res.message)
        }
      })
    },

    gotoLiveView(){
      var url= this.liveActivityInfo.url
      var a = document.createElement('a');
      a.target='_blank'
      a.href = url;
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
  },
  components:{
    Pop_image,
    Footer_lg,
    Footer_sm,
    luckDraw,
    Remind_pay,
    ElImageViewer
  },
  watch:{
    $route:{
      // immediate:true,
      handler:'changePage',
    },
    "$store.state.showFeedBack":{
      handler:function (newValue, oldValue){
        if (newValue == true){
          this.showMessage = newValue
          this.getMessageInfo()
          this.$nextTick(()=>{
            this.scrollListener()
          })
        }
      }
    }
  },
}
</script>

<style scoped>
.nav-container{
  height: 100%;
}
.el-header{
  background-color: #393948;
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  align-items: center;
}
.el-header .el-menu-item{
  font-size: 16px;
  padding: 0 10px;
  margin: 0 6px;
}
.el-header>div{
  display: flex;
  align-items: center;
  color: #fff;
}
.el-header .icons{
  margin-right: 0px;
  cursor: pointer;
}
.el-header .icons img:first-child,.el-header .icons img:nth-child(3){
  margin-right: 24px;
}
#logo{
  width: 72px;
  height: 20px;
  margin-left: 132px;
  margin-right: 36px;
}
.el-aside{
  background-color: #FFFFFF;
}
.el-main{
  background-color: #F7F9FC;
  padding-bottom: 60px !important;
}
.el-menu{
  border: none;
}
.lg.el-menu--horizontal>.el-menu-item.is-active{
  border-bottom: 3px solid #AB54DB !important;
}
.menu-vertical .el-menu-item{
  box-sizing: border-box;
  padding-left: 36px !important;
}
.menu-vertical .el-menu-item.is-active{
  border-right: 2px solid #AB54DB !important;
}
.lg{
  position: relative;
}
.el-footer{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 279px !important;
  background-color: #151924;
}
.message-bax div >>>.el-drawer.ttb, >>>.el-drawer.btt {
  width: 400px;
  height: 70% !important;
  position: absolute;
  left: unset;
  right: 2%;
  bottom: 2% !important;
  border-radius: 10px 10px 0 0;
}

.message-bax-top{
  height: 84.5% !important;
}
.message-bax-bottom{
  height: 15% !important;
}
/*.message-bax >>>.el-drawer__header{*/
/*  padding: 0;*/
/*  display: none;*/
/*}*/

>>>.el-popover >>>.el-popper >>>.el-popover--plain {
  left: unset !important;
  right: 16px !important;
}
>>>.el-popper[x-placement^="bottom"] .popper__arrow {
  left: 185px !important;
}

.download-app {
  margin: 0 32px;
  width: 30px;
}

@media screen and (min-width:1079px ){
  .lg,.img-lg{
    display: flex;
  }
  .img-lg{
    display: inline-block;
  }
  .sm,.img-sm{
    display: none;
  }
  .img-sm{
    display: none;
  }
}
.el-main{
  /*margin-bottom: 279px;*/
}
@media screen and (max-width: 1079px){
  .lg{
    display: none;
  }
  .img-lg{
    display: none;
  }
  .sm{
    display: block;
    background-color: #F7F9FC;
    height: 92%;
  }
  .img-sm{
    display: inline-block;
  }
#logo{
  margin-left: 16px;
}
.el-header .icons{
  margin-right: 0px;
}
.sm-menu{
  background-color: #FFFFFF;
}

.sm .el-menu{
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
}
.sm .el-menu .el-menu-item img{
  width: 20px;
  height: 20px;
  margin-right: 6px;
}

}

@media screen and (max-width: 2048px){
  .message-bax div >>>.el-drawer.ttb, >>>.el-drawer.btt {
    width: 400px;
    height: 70% !important;
    position: absolute;
    left: unset;
    right: 2%;
    bottom: 2% !important;
  }
  .message-bax-top{
    height: 80% !important;
  }
  .message-bax-bottom{
    height: 19% !important;
  }
}

@media screen and (max-width: 1920px){
  .message-bax div >>>.el-drawer.ttb, >>>.el-drawer.btt {
    width: 400px;
    height: 700px !important;
    position: absolute;
    left: unset;
    right: 2%;
    bottom: 2% !important;
  }
  .message-bax-top{
    height: 80% !important;
  }
  .message-bax-bottom{
    height: 19% !important;
  }
}

@media screen and (max-width: 900px){
  .message-bax div >>>.el-drawer.ttb, >>>.el-drawer.btt {
    width: 400px;
    height: 700px !important;
    position: absolute;
    left: unset;
    right: 2%;
    bottom: 2% !important;
  }
  .message-bax-top{
    height: 80% !important;
  }
  .message-bax-bottom{
    height: 19% !important;
  }

  el-popover[x-placement^=bottom] .popper__arrow{
    /*el-popover上边三角下层的颜色---也就是视觉上的边框*/
    border-bottom-color: rgba(54,229,255,1);
  }
  .el-popover[x-placement^=bottom] .popper__arrow::after{
    /*el-popover上边三角表层的颜色---也就是视觉上的角*/
    border-bottom-color: rgba(8,40,100,1);
  }

  .download-app {
    margin: 0 16px !important;
    width: 26px;
    position: absolute;
    right: 90px;
  }
}

.user-icons-info{
  text-align: center;
  width: 100%;
  margin: 48px 0;
}
.user-icons-info img:first-child{
  width: 88px;
  height: 88px;
}
.user-icons-info div{
  margin: 16px 0 4px 0;
  font-size: 18px;
  font-weight: 500;
  color: #000613;
}
.user-icons-info img:nth-child(3),.user-icons-info img:nth-child(4){
  /*margin-right: 10px;*/
}

/**
 抽屉的header的边距
 */
/deep/.el-drawer__header {
  margin-bottom: 0;
  padding: 20px 20px 0;
}

/**
add-static
*/
.add-static>div>img{
  width: 20px;
  height: 20px;
}
.add-static{
  width: 100%;
  background: #FFFFFF !important;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 14px;
  height: 65px !important;
}
.add-static>div{
  text-align: center;
  font-size: 14px;
}
.add-menu li{
  width: 100%;
}

.menuImg > div li:hover{
  background: rgba(44, 117, 255, 0.1);
  color: #AB54DB;
}

.dialog2 >>>.el-dialog--center .el-dialog__body {
  padding: 25px 25px 15px !important;
}

.dialog_down >>>.el-dialog--center{
  border-radius: 12px;
  padding: 40px 0;
}

.message_icon {
  position: fixed;
  bottom: 2%;
  right: 2%;
  z-index: 888;
  background: #AB54DB;
  width: 44px;
  height: 44px;
  box-shadow: 0px 2px 4px 0px rgba(44, 117, 255, 0.2), 0px 4px 8px 0px rgba(44, 117, 255, 0.1);
  /*opacity: 0.7;*/
}

.message-bax >>>.el-drawer__body{
  overflow: hidden;
}

.message-bax >>>.el-drawer__wrapper {
  position: absolute;
  right: 5%;
  bottom: 10%;
  overflow: hidden;
  margin: 0;
}

.message-bax >>>.el-radio__input {
  display: none;
  border-radius: 36px;
}

.message-bax .is-checked {
  color: white;
  border: none;
  background-color: #AB54DB;
}

.message-bax .el-radio--medium.is-bordered {
  padding: 0px;
  border-radius: 36px;
  height: 23px;
  /*margin: 0px auto;*/
  margin-left: 10px;
  margin-right: 0;
  vertical-align: middle;
  width: 12%;
  text-align: center;
}

.message-bax >>>.el-radio__label {
  font-size: 14px;
  vertical-align: middle;
  line-height: 23px;
  padding-left: 0px;
}

.message-bax >>>.el-radio__input.is-checked + .el-radio__label {
  color: white;
}

.message-bax .avatar-uploader {
  /*margin: 8px 20px;*/
  margin: 8px 15px 8px 20px;
  width: 28px;
  height: 28px;
  /*border: 1px #EAECEF solid;*/
  border-radius: 1px;
}

.message-bax .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.message-bax .avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.message-bax .avatar {
  width: 50px;
  position: absolute;
  left: 20px;
  bottom: 20px;
  display: block;
}

.message-bax >>>.el-textarea__inner{
  border: unset;
  resize: none;
  padding: 5px 15px 5px 0 !important;
}

.message-bax .submit-btn {
  position: absolute;
  display: inline-block;
  background-color: #AB54DB;
  right: 20px;
  top: 0;
  height: 40px;
  bottom: 0;
  margin: auto 0;
  vertical-align: middle;
}

::-webkit-scrollbar {
  width: 5px;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(26, 25, 25, 0.3);
  background-color: rgba(0, 0, 0, 0.1);
}

.menu-vertical{
  margin-top: 10px;
}

.language_selected{
  color: #AB54DB;
}

.shake {
  animation: shake 4s 0s infinite;
  -webkit-animation: shake 4s 0s infinite;
}

@keyframes shake { /* 水平抖动，核心代码 */
  0%, 86% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  88% {
    -webkit-transform: rotate(8deg);
    transform: rotate(8deg);
  }

  90% {
    -webkit-transform: rotate(-8deg);
    transform: rotate(-8deg);
  }

  92% {
    -webkit-transform: rotate(8deg);
    transform: rotate(8deg);
  }

  94% {
    -webkit-transform: rotate(-8deg);
    transform: rotate(-8deg);
  }

  96% {
    -webkit-transform: rotate(8deg);
    transform: rotate(8deg);
  }

  98% {
    -webkit-transform: rotate(-8deg);
    transform: rotate(-8deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.scrolling-text {
  overflow: hidden;
  white-space: nowrap;
  animation: marquee 10s linear infinite;
}
@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform:  translateX(-100%);
  }
}
.guanggao-t-1 {
  background: linear-gradient(120deg, #87E2CE 10%, #4A8CF6 40%, #8C7BFA 100%); /* 从左到右渐变 */
  -webkit-background-clip: text; /* Safari/Chrome支持背景裁切文本内容 */
  -webkit-text-fill-color: transparent; /* Safari/Chrome透明化文字颜色 */
  font-size: 18px;
}
.guanggao-t-2 {
  background: linear-gradient(120deg, #CF70E0 10%, #F97ABF 35%, #F192A5 50%, #FDBF95 100%); /* 从左到右渐变 */
  -webkit-background-clip: text; /* Safari/Chrome支持背景裁切文本内容 */
  -webkit-text-fill-color: transparent; /* Safari/Chrome透明化文字颜色 */
  font-size: 18px;
}
.guanggao-t-3 {
  background: linear-gradient(120deg, #9CA8FF 10%, #FBA3F8 100%); /* 从左到右渐变 */
  -webkit-background-clip: text; /* Safari/Chrome支持背景裁切文本内容 */
  -webkit-text-fill-color: transparent; /* Safari/Chrome透明化文字颜色 */
  font-size: 18px;
}
</style>
