<style scoped>
@media screen and (max-width: 900px) {
  #bg {
    text-align: center;
    position: relative;
  }

  .luck_draw_title{
    position: absolute;
    width: 300px;
    top: -60px;
    right: 22px;
  }

  .bg {
    width: 295px;
    left: 0px;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0 auto;
  }

  .pointer {
    position: absolute;
    width: 100px;
    z-index: 10;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .turntable {
    width: 258px;
    position: absolute;
    z-index: 5;
    left: 0px;
    right: 0;
    top: 0;
    bottom: 5px;
    margin: auto;
    border-radius: 50%;
    transition: all 4s; /*动画执行时间为4s */
  }

  .close{
    position: absolute;
    /*bottom: -60px;*/
    top: 0px;
    width: 30px;
    /*left: 0;*/
    right: 10px;
    /*margin: auto;*/
  }

  .center_go{
    width: 60px;
  }

  .tip{
    position: absolute;
    width: 260px !important;
    text-align: left !important;
    bottom: 0 !important;
    z-index: 2147483647;
    color: rgb(230, 162, 60);
    font-weight: 600;
    margin: auto;
    left: 0;
    right: 0;
  }

  .tip p{
    margin: 0;
    display: block;
  }

  .dialog1 /deep/.el-dialog--center {
    margin-top: 0px !important;
    background: transparent;
    box-shadow: unset;
  }

  .dialog1 /deep/.el-dialog__body {
    padding: 60px 0 0 0 !important;
    text-align: center;

  }
  /deep/.el-dialog__wrapper{
   padding-top: 0px !important;
  }

  .dialog_ticket{
    background: white;
    border-radius: 6px;
    text-align: center;
    /*width: 314px;*/
    margin: 0 auto;
    padding-top: 40px;
  }
  .dialog_ticket button {
    width: 94%;
    height: 40px;
    background-color: #FF812E;
    color: white;
    border-radius: 2px;
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 16px;
    border: none;
  }
}

@media screen and (min-width: 900px) {

  .bg {
    width: 40%;
    left: 0px;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .pointer {
    position: absolute;
    width: 100px;
    z-index: 10;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .turntable {
    width: 35%;
    position: absolute;
    z-index: 5;
    left: 0px;
    right: 0;
    top: 0;
    bottom: 3px;
    margin: auto;
    border-radius: 50%;
    transition: all 4s; /*动画执行时间为4s */
  }

  /*.close{*/
  /*  position: absolute;*/
  /*  bottom: -60px;*/
  /*  width: 40px;*/
  /*  left: 0;*/
  /*  right: 0;*/
  /*  margin: auto;*/
  /*}*/

  .close{
    position: absolute;
    width: 40px;
    right: 0;
    top: 0px;
    margin: auto;
  }

  .center_go{
    width: 60px;
  }

  .luck_draw_title {
    position: absolute;
    width: 490px;
    top: -70px;
    left: 0;
    right: 0;
    margin: auto;
  }

  .dialog_ticket{
    background: white;
    border-radius: 6px;
    text-align: center;
    width: 318px;
    /*height: 303px;*/
    margin: 0 auto;
    padding-top: 40px;
  }

  .dialog1 /deep/.el-dialog__body {
    padding: 65px 0 0 0 !important;
    text-align: center;
    height: 760px;
    max-height: 90%;
    margin: 0 auto;
  }

  .dialog1 /deep/.el-dialog--center{
    top: 50%;
    height: 100%;
    max-height: 100%;
    text-align: center;
    background: transparent;
    box-shadow: unset;

  }
  .dialog_ticket button {
    width: 80%;
    height: 40px;
    background-color: #FF812E;
    color: white;
    border-radius: 2px;
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 16px;
    border: none;
  }
  /*.el-dialog--center {*/
  /*  text-align: center;*/
  /*  box-shadow: unset;*/
  /*  height: 700px;*/
  /*}*/

  /*.dialog1 > div{*/
  /*  max-height: 100%;*/
  /*}*/
}

@media screen and (min-width: 1920px) {
  .bg {
    width: 35%;
    left: 0px;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .pointer {
    position: absolute;
    width: 7%;
    z-index: 10;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .turntable {
    width: 30.7%;
    position: absolute;
    z-index: 5;
    left: 0px;
    right: 0;
    top: 0;
    bottom: 3px;
    margin: auto;
    border-radius: 50%;
    transition: all 4s; /*动画执行时间为4s */
  }
  .dialog1 /deep/.el-dialog--center{
    top: 50%;
    height: 100%;
    max-height: 100%;
    text-align: center;
    background: transparent;
    box-shadow: unset;
  }
  .tip{
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 130px !important;
    z-index: 2147483647;
    color: rgb(230, 162, 60);
    font-weight: 600;
    margin: auto;
    left: 0;
    right: 0
  }
}

@media screen and (min-width: 2100px) {

  .bg {
    width: 50%;
    left: 0px;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .pointer {
    position: absolute;
    width: 12%;
    z-index: 10;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .turntable {
    width: 44%;
    position: absolute;
    z-index: 5;
    left: 0px;
    right: 0;
    top: 0;
    bottom: 3px;
    margin: auto;
    border-radius: 50%;
    transition: all 4s; /*动画执行时间为4s */
  }
  .dialog1 /deep/.el-dialog--center{
    top: 50%;
    height: 100%;
    max-height: 100%;
    text-align: center;
    background: transparent;
    box-shadow: unset;
  }
  .dialog1 /deep/.el-dialog__body {
    padding: 65px 0 0 0 !important;
    text-align: center;
    height: 760px;
    max-height: 90%;
    margin: 0 auto;
  }

  .tip{
    bottom: 300px !important;
  }
}

#bg {
  text-align: center;
  position: relative;
}

.center_go{
  position: absolute;
  z-index: 11;
  margin: auto;
  left: 0;
  right: 0;
  top: 2%;
  bottom: 0;
}

.tip{
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 160px;
  z-index: 2147483647;
  color: rgb(230, 162, 60);
  font-weight: 600;
  margin: auto;
  left: 0;
  right: 0
}

.tip p{
  display: inline-block;
  margin-right: 10px;
}

>>>.el-dialog__header {
  display: none;
}

.ticket{
  width: 134px;
  display: block;
  margin: 0 auto;
}

.dialog_ticket :nth-child(2) {
  display: block;
  font-size: 24px;
  font-family: PingFangTC-Medium, PingFangTC;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  margin-top: 30px
}
.dialog_ticket :nth-child(3) {
  display: block;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.45);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  margin-top: 16px
}

.dialog_ticket :nth-child(4) {
  display: block;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 400;
  margin-top: 4px
}

.dialog2 >>>.el-dialog__body{
  padding: 0px;
}

.next_time {
  color: #FF812E;
  display: block;
  border-bottom: 1px #FF812E solid;
  width: fit-content;
  margin: 0px auto 30px;
  font-size: 16px;
  cursor: pointer;
  letter-spacing: 2px;
}

</style>

<template>
  <div>
    <el-dialog
        :visible.sync="isShow"
        :center="true"
        width="650px"
        :show-close="false"
        :fullscreen="true"
        @open="shows"
        :close-on-click-modal="false"
        style="background-color: transparent;padding-top: 80px"
    class="dialog1">
      <div id="bg">
        <img class="luck_draw_title" style="" src="../../assets/img/luckDraw/luck_draw_title.png">
        <img class="bg" src="../../assets/img/luckDraw/turntable-bg.png">
        <img ref="turntable" class="turntable" src="../../assets/img/luckDraw/turntable.png" alt="turntable">
        <img @click="go" class="pointer" style="cursor: pointer" src="../../assets/img/luckDraw/guding.png" alt="pointer">
        <img @click="go" class="center_go" style="cursor: pointer" src="../../assets/img/luckDraw/go.png">
        <img class="close" @click="isShow = false" style="cursor: pointer" src="../../assets/img/luckDraw/close.png">
      </div>
      <div class="tip" style="color: #FEFCD4;font-weight: 600">
        提示：
        <p style="">1.此活动适用所有Felton会员</p>
        <p style="">2.每个会员账号只限抽奖一次</p>
        <p style="">3.减免时间不可累计、叠加、转让</p>
        <p style="">4.活动最终解释权归Felton量化团队所有</p>
      </div>
    </el-dialog>

    <el-dialog
        :visible.sync="ticketIsShow"
        title=""
        :center="true"
        :close-on-click-modal="false"
        width="318px"
        :show-close="false"
    class="dialog2">
      <div class="dialog_ticket">
        <img v-if="days == 30" class="ticket" src="../../assets/img/luckDraw/ticket30.png" alt="pointer">
        <img v-if="days == 25" class="ticket" src="../../assets/img/luckDraw/ticket25.png" alt="pointer">
        <img v-if="days == 20" class="ticket" src="../../assets/img/luckDraw/ticket20.png" alt="pointer">
        <img v-if="days == 15" class="ticket" src="../../assets/img/luckDraw/ticket15.png" alt="pointer">
        <img v-if="days == 10" class="ticket" src="../../assets/img/luckDraw/ticket10.png" alt="pointer">
        <img v-if="days == 5" class="ticket" src="../../assets/img/luckDraw/ticket5.png" alt="pointer">
        <img v-if="days == 1" class="ticket" src="../../assets/img/luckDraw/ticket1.png" alt="pointer">
        <span>恭喜您中獎啦!</span>
        <span>獲得<span>{{ticket}}</span>權益</span>
        <span>權益有效期：{{ticket_startTime}} 至 {{ticket_endTime}}</span>
        <button @click="go_robot()">去設置機器人</button>
        <span class="next_time" @click="i_know()">下次再説</span>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import {addUserActivity, getUserActivity} from "../../server/user";
export default {
  name:'luckDraw',
  data(){
    return {
      isGo: false,              //是否正在执行动画
      oTurntable: '',           //执行动画的对象
      randomDeg: 0,             //即将旋转的度数
      lastDeg:0,                //上一次旋转的度数
      goTimes:1,			          //抽奖次数
      isShow: false,
      ticketIsShow: false,
      ticket: '',
      days:'',
      luckDays: '',
      ticket_startTime: '2021-10-25',
      ticket_endTime: '2021-11-24'
    }
  },

  watch: {
    isShow(val){
      if(val == true){
        this.$util.lock()
      } else {
        this.$util.unLock()
      }
    },


  },

  created() {
    window.addEventListener("load",function() {
      // Set a timeout...
      setTimeout(function(){
        // Hide the address bar!
        window.scrollTo(0, 1);
      }, 0);
    });
    window.onload = function() {
      scroll();
    }

    function scroll() {
      if(document.documentElement.scrollHeight <= document.documentElement.clientHeight) {
        var bodyTag = document.getElementsByTagName('body')[0];
        bodyTag.style.height = document.documentElement.clientWidth / screen.width * screen.height + 'px';
      }
    }

    window.addEventListener('setItem', () => {
      if (localStorage.getItem('isShow', '1')) {
        this.isShow = true;
        localStorage.removeItem('isShow')
      }
    });
  },
  methods:{
    // go(){
    //   this.$message.warning('活動暫未開始，請耐心等待！')
    //   return;
    // },
    go(){
      this.oTurntable = this.$refs.turntable
      if(!this.isGo && this.goTimes >0){
        this.addUserActivity()
      }
      else if(!this.isGo && this.goTimes <=10){
        // alert('抱歉您的抽奖次数用完了')
        this.$message.warning('您的抽獎機會已用完，請等待下次抽獎活动')
      }
      else return    			//表明正在抽奖，未结束扔点击无效
    },
    getRandom(n,m){             //该方法能产生[n,m]之间随机数
      let result = Math.floor(Math.floor(Math.random()*(m-n+1)+n))
      return result;
    },
    getNumber(){
      /*
        调整中奖概率(让每次旋转前指针都在初始位置，这样才准确)
        想转到第一项，需要转：360/7*0 + 360/7/2;   --->该项为超级大奖奖项
        想转到第二项，需要转：360/7*1 + 360/7/2;
        想转到第三项，需要转：360/7*2 + 360/7/2;
        想转到第四项，需要转：360/7*3 + 360/7/2;
        想转到第五项，需要转：360/7*4 + 360/7/2;
        想转到第六项，需要转：360/7*5 + 360/7/2;
        想转到第七项，需要转：360/7*6 + 360/7/2;   --->该项为未中奖项
      */
      // let number = this.getRandom(1,100)
      // let caseNum = ''
      // if(number >= 0 && number <= 0){
      //   caseNum = 0             //粗略概率为50%
      // }else if(number > 0 && number <0){
      //   caseNum = 1             //粗略概率为0%
      // }else if(number > 0 && number <= 0){
      //   caseNum = 2             //粗略概率为17%
      // }else if(number > 0 && number <=40){
      //   caseNum = 3             //粗略概率为0%
      // }else if(number > 40 && number <=80){
      //   caseNum = 4             //粗略概率为0%
      // }else if(number > 0 && number <=0){
      //   caseNum = 5             //粗略概率为5%
      // }else if(number > 80 && number <90){
      //   caseNum = 6             //粗略概率为17%
      // }else if(number > 90 && number <=100){
      //   caseNum = 7             //粗略概率为17%
      // }
      let number = this.luckDays
      let caseNum = ''
      if(number == 30){
        caseNum = 0             //粗略概率为50%
      }else if(number == 0){
        caseNum = 1             //粗略概率为0%
      }else if(number == 20){
        caseNum = 2             //粗略概率为17%
      }else if(number == 5){
        caseNum = 3             //粗略概率为0%
      }else if(number == 10){
        caseNum = 4             //粗略概率为0%
      }else if(number == 25){
        caseNum = 5             //粗略概率为5%
      }else if(number == 1){
        caseNum = 6             //粗略概率为17%
      }else if(number == 15){
        caseNum = 7             //粗略概率为17%
      }

      switch(caseNum){
        case 0:
          this.ratating(360/8*0,"免30天燃料費", 30);
          break;
        case 1:
          this.ratating(360/8*1,"謝謝參與", 0);
          break;
        case 2:
          this.ratating(360/8*2,"免20天燃料費", 20);
          break;
        case 3:
          this.ratating(360/8*3,"免5天燃料費", 5);
          break;
        case 4:
          this.ratating(360/8*4,"免10天燃料費", 10);
          break;
        case 5:
          this.ratating(360/8*5,"免25天燃料費", 25);
          break;
        case 6:
          this.ratating(360/8*6,"免1天燃料費", 1);
          break;
        case 7:
          this.ratating(360/8*7,"免15天燃料費", 15);
          break;
        default:
          this.ratating(360/8*1,"謝謝參與", 0);
          break;
      }
    },
    ratating(deg,text,days){
      this.goTimes --
      this.isGo = true
      let times = this.getRandom(3,6)//圈数(从用户体验角度考虑，设计随机转3-6圈。最少3圈，最多6圈)

      this.randomDeg =  deg + 360 * times      //记录这次要旋转的度数(传来的度数+圈数)
      let realDeg = (360 - this.lastDeg % 360) + this.lastDeg + this.randomDeg
      /*上次指针离初始状态的度数 + 上次的度数 + 这次要旋转的度数
      (这样的目的是为了每次旋转都从原点开始，保证数据准确)*/
      this.oTurntable.style.transform = `rotate(${realDeg}deg)`;
      setTimeout(() => {
        this.isGo = false
        // console.log(`以原点为基准共旋转了${this.randomDeg}度,
        // 			以一圈为基准相对旋转了${this.randomDeg % 360}度,最终结果为${text}`)
        this.lastDeg = realDeg    //把这次度数存储起来，方便下一次获取
        // this.$message.success(this.lastDeg)
        // this.isShow = false;
        // this.ticketIsShow = true;
        // this.addUserActivity(days);
        this.isShow = false;
        this.ticketIsShow = true
        this.ticket= text;
        this.days = days;
        this.ticket_startTime = this.$util.currentDate('.')
        // this.ticket_endTime = this.$util.formatDate(days,'.')
        // localStorage.setItem('updateTime', this.ticket_endTime)
        this.resetSetItem('updateTime', this.ticket_endTime);
      },4000)                     //4000ms为css里面写的执行动画的时间
    },
    shows() {
      this.oTurntable = this.$refs.turntable
    },
    i_know(){
      this.ticketIsShow = false;
    },
    async getLuckDrawTimes() {
      const res = await getUserActivity(1);
      if (res) {
        if (res.success) {
          if (!res.data){
            this.isShow = true;
          }else{
            this.isShow = false;
          }
        } else {
          this.isShow = false;
        }
      }
    },

    async addUserActivity(){
      const res = await addUserActivity(1);
      if (res){
        if (res.success) {
          this.luckDays = res.data.days
          var date = res.data.endDate
          date = date.replaceAll('-', '/')
          this.ticket_endTime = this.$util.formatDate_c(date, '.');
          this.getNumber()

          // this.isShow = false;
          // this.ticketIsShow = true
        }else {
          this.$message.error(res.message)
          // this.isShow = false;
        }
      }
    },

    go_robot(){
      if (this.$route.path == 'robot'){
        this.isShow = false
        return;
      }
      this.ticketIsShow = false
      this.$router.push('robot')
    },
  },
  mounted(){
    this.getLuckDrawTimes();
    // this.oTurntable = this.$refs.turntable

  }
}
</script>
